<template>
  <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
  <div>
    <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
    <div class="container col-12 offset-0 col-md-6 offset-md-3 tugradient1" style="padding-top: 5vh; padding-bottom: 5vh: outline:0.01em dashed red">
      <table
       
        border="0"
        style="padding-left: 2.5vh; padding-right: 2.5vh"
      >
        <tr>
          <td>
            <table width="100%" border="0" s>
              <tr>
                <td width="30%">
                  <span
                    class="primary-general-text divl"
                    style="display: block; line-height: 15px"
                    >&nbsp;</span
                  >
                </td>
                <td width="40%">
                  <img
                    class="tulogo"
                    style="height: 6vh; padding-bottom: 1vh"
                    alt="thankU logo"
                    src="../assets/tutxtlogomd.webp"
                  />
                </td>
                <td width="30%">
                  <div class="divr">
                    <button @click="doShowHome" class="text-only-button cancel">
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td class="tuparachunkpadding" style="padding-top: 1vh; padding-bottom: 1vh">
            <span class="tusmallerfontsize tufont">
              ThankU.app Privacy Policy
              <p></p>
              Effective Date: June 11th, 2018
              <p></p>
              Welcome to ThanksU.app’s Privacy Policy!
              <p></p>
              ThankU.app cares deeply about the privacy of its visitors and
              users, and is fully committed to protect their personal
              information and use it properly in compliance with data privacy
              laws. This policy describes how we may collect and use personal
              information, and the rights and choices available to our visitors
              and users regarding such information.
              <p></p>
              We strongly urge you to read this policy and make sure you fully
              understand it, before you access or use any of our services.
              <p></p>
              Capitalized terms which are not defined herein shall have the
              meaning ascribed to them in our Terms and Conditions.
              <p></p>
              1. Please read carefully!
              <p></p>
              This ThankU.app Privacy Policy (“Privacy Policy”) describes how we
              (Appsanely Ltd., “ThankU”, “we” or “us”) collect and use
              information pertaining to each of our unregistered visitors and
              registered users (each, a “Visitor” or “User” (respectively), or
              “you”), in connection with their access to and use of ThankU’s
              websites (including www.thanku.app and any of its subdomains, the
              “Website”), mobile applications (“ThankU Apps”), and related
              services (collectively, the “Services”).
              <p></p>
              The purpose of this Privacy Policy is to provide you with a clear
              explanation of when, why and how we collect and use your personal
              information, as well as an explanation of your statutory rights.
              This Privacy Policy is not intended to override the terms of any
              contract you have with us, nor any rights you might have under
              applicable data privacy laws.
              <p></p>
              Read this policy and make sure you fully understand our practices
              in relation to your personal information, before you access or use
              any of our Services. If you read and fully understand this Privacy
              Policy, and remain opposed to our practices, you must immediately
              leave this website, application or service, and avoid or
              discontinue all use of any of our Services. If you have any
              questions or concerns regarding this policy, please contact us at
              hello@thanku.app.
              <p></p>
              This policy describes our privacy practices – what information we
              collect on our visitors and users, how we collect it, what we do
              with it, how we protect it, and your rights regarding your
              information.
              <p></p>
              By accessing or using any of our services, you acknowledge that
              you have read this Privacy Policy
              <p></p>
              2. What information do we collect?
              <p></p>
              2.1. Visitor and User Information
              <p></p>
              We collect two types of information regarding our Visitors and
              Users:
              <p></p>
              Un-identified and non-identifiable information pertaining to a
              Visitor or un-identified User, which may be made available to us,
              or collected automatically via his/her use of the Services
              (“Non-personal Information”). Such Non-personal Information does
              not enable us to identify the Visitor or User from whom it was
              collected. The Non-personal Information collected by us mainly
              consists of technical and aggregated usage information, such as
              Visitors’ and Users’ browsing and ‘click-stream’ activity on the
              Services, session heatmaps and scrolls, non-identifying
              information regarding the Visitor’s or User’s device, operating
              system, internet browser, screen resolution, language and keyboard
              settings, internet service provider, referring/exit pages,
              date/time stamps, etc.
              <p></p>
              Individually identifiable information, namely information that
              identifies an individual or may with reasonable efforts cause the
              identification of an individual, or may be of private or sensitive
              nature (“Personal Information”). The Personal Information
              collected by us mainly consists of Users’ contact details (e.g.,
              e-mail address), Users’ mailing details (name, physical mailing
              address) if they request us to provide them with ThankU
              contactless tags in order to benefit from using our Services,
              details regarding a browsing or usage session (IP address,
              Geo-location and/or device unique identifier), details regarding
              connected third party accounts (such as the e-mail or username for
              a connected PayPal, Google or Facebook account), and any other
              Personal Information provided to us by Visitors and/or Users
              through their access to and/or use of the Services. For the
              avoidance of doubt, any Non-personal Information that is connected
              or linked to Personal Information (for example, in order to
              improve the Services we offer) is deemed and treated by us as
              Personal Information, as long as such connection or linkage
              exists.
              <p></p>
              We collect two types of information: Personal Information (which
              could be used to uniquely identify an individual) and Non-personal
              Information (which is non-identifying).
              <p></p>
              We collect such information regarding our users and visitors, job
              applicant and anyone else who may provide it to us.
              <p></p>
              2.2. ThankU Jobs Applicant Information
              <p></p>
              We also collect information that is provided to us by ThankU jobs
              candidates (“Applicants”), when they apply to any of the open
              positions published through our Website, by e-mail or otherwise
              (as further described in Section ‎6 below).
              <p></p>
              3. How do we collect such information?
              <p></p>
              There are two main methods we use:
              <p></p>
              We collect information through your use of the Services. When you
              visit or use our Services, including when you browse the Website,
              register a User account, and/or download and use any ThankU Apps,
              use our Services to give tips, donations, and/or make other
              payments, we are aware of it and will usually gather, collect and
              record such uses, sessions and related information, either
              independently or with the help of third-party services as detailed
              in Section ‎7 below, including through the use of “cookies” and
              other tracking technologies, as further detailed in Section ‎8
              below.
              <p></p>
              We collect information which you provide us voluntarily. For
              example, we collect the Personal Information you provide us when
              you register to our Services; when you sign in to our Services via
              third party services such as Facebook or Google; when you submit
              or upload such Personal Information as you use in any of our
              Services; and/or when you contact us directly by any communication
              channel (e.g. emails).
              <p></p>
              We also collect information from third party sources as described
              in Section 7 below.
              <p></p>
              To recap:
              <p></p>
              When you visit or use our services – you may also provide us with
              certain information (either automatically through the process of
              using the Service, or by manually submitting it).
              <p></p>
              4. Why do we collect such information?
              <p></p>
              We collect such Non-personal and Personal Information for the
              following purposes:
              <p></p>
              To provide and operate the Services;
              <p></p>
              To further develop, customize and improve our Services, based on
              Visitors’ and Users’ common or personal preferences, experiences
              and difficulties;
              <p></p>
              To provide our Users with ongoing customer assistance and
              technical support;
              <p></p>
              To be able to contact our Visitors and Users with general or
              personalized service-related notices and promotional messages (as
              further detailed in Section ‎9 below);
              <p></p>
              To facilitate, sponsor and offer certain contests, events and
              promotions, determine participants’ eligibility, monitor
              performance, contact winners and grant prizes and benefits;
              <p></p>
              To create aggregated statistical data and other aggregated and/or
              inferred Non-personal Information, which we or our business
              partners may use to provide and improve our respective services;
              <p></p>
              To enhance our data security and fraud prevention capabilities;
              <p></p>
              To consider Applicants’ candidacy for working at Appsanely Ltd (as
              further detailed in Section ‎6 below); and
              <p></p>
              To comply with any applicable laws and regulations.
              <p></p>
              We will only use your Personal Information for the purposes set
              out in Section 4 where we are satisfied that:
              <p></p>
              our use of your Personal Information is necessary to perform a
              contract or take steps to enter into a contract with you (e.g. to
              provide you with a website builder, to provide you with our
              customer assistance and technical support), or
              <p></p>
              our use of your Personal Information is necessary to comply with a
              relevant legal or regulatory obligation that we have, or
              <p></p>
              our use of your Personal Information is necessary to support
              legitimate interests that we have as a business (for example, to
              maintain and improve our Services by identifying user trends and
              the effectiveness of ThankU campaigns and identifying technical
              issues), provided it is conducted at all times in a way that is
              proportionate, and that respects your privacy rights.
              <p></p>
              To recap:
              <p></p>
              We collect and use information in order to provide our services
              and make them better and safer.
              <p></p>
              We also collect and use information in order to contact our
              visitors, users and job applicants, and in order to comply with
              the laws applicable to us.
              <p></p>
              5. Where do we store your information?
              <p></p>
              ThankU Visitors’ and ThankU Users’ Personal Information may be
              maintained, processed and stored by ThankU and our authorized
              affiliates and service providers in the United States of America,
              in Europe (including in the United Kingdom), and in other
              jurisdictions as necessary for the proper delivery of our Services
              and/or as may be required by law (as further explained below).
              <p></p>
              ThankU Jobs Applicants Information will be maintained, processed
              and stored in the United Kingdom, in the applied position’s
              location(s), and as necessary, in secured cloud storage provided
              by our Third Party Services.
              <p></p>
              ThankU.app Ltd. is based in the United Kingdom.
              <p></p>
              ThankU affiliates and service providers that store or process your
              Personal Information on ThankU’s behalf are each contractually
              committed to keep it protected and secured, in accordance with
              industry standards and regardless of any lesser legal requirements
              which may apply in their jurisdiction.
              <p></p>
              5.1.
              <p></p>
              We may store and process Personal Information in the USA, Europe,
              or other jurisdictions, whether by ourselves or with the help of
              our affiliates and service providers.ֿ
              <p></p>
              Under certain conditions, more fully described on the Privacy
              Shield website, you may invoke binding arbitration when other
              dispute resolution procedures have been exhausted.
              <p></p>
              Upon request ThankU will provide you with information about
              whether we hold any of your Personal Information. We will respond
              to your request within a timeframe imposed by local laws or a
              reasonable timeframe.
              <p></p>
              Please note that permanently deleting your ThankU account erases
              all of your information from ThankU's databases except data
              directly related with any transactions on your account. After
              completing this process, you can no longer use any of your ThankU
              Services, your User account and all its data other than the
              aforementioned transaction data will be removed permanently, and
              ThankU will not be able to restore your account or retrieve your
              data in the future. If you contact ThankU Support in the future,
              the system will not recognize your account and Support agents will
              not be able to locate the deleted account.
              <p></p>
              6. ThankU jobs applications
              <p></p>
              ThankU welcomes all qualified Applicants to apply to any of the
              open positions published at https://www.thanku.app, by sending us
              their contact details and CV (“Applicants Information”) through
              any means provided by us.
              <p></p>
              We understand that privacy and discreetness are crucial to our
              Applicants, and are committed to keep Applicants Information
              private and use it solely for ThankU’s internal recruitment
              purposes (including for identifying Applicants, evaluating their
              applications, making hiring and employment decisions, and
              contacting Applicants by phone or in writing).
              <p></p>
              Please note that ThankU may retain Applicants Information
              submitted to it for no longer than two years after the applied
              position has been filled or closed. This is done so we can
              re-consider Applicants for other positions and opportunities at
              ThankU; so we can use their Applicants Information as reference
              for future applications submitted by them; and if the Applicant is
              hired, for additional employment and business purposes related to
              his/her work at ThankU.
              <p></p>
              If you previously submitted your Applicants Information to ThankU,
              and now wish to access it, update it or have it deleted from
              ThankU’s systems, please contact us at hello@thanku.app.
              <p></p>
              We welcome all qualified job seekers to apply to any of our open
              positions, by sending us their contact details and CV.
              <p></p>
              We will use such information solely for our internal recruitment,
              employment and business purposes.
              <p></p>
              7. Sharing personal information with third parties
              <p></p>
              ThankU may share your Personal Information with third parties (or
              otherwise allow them access to it) only in the following manners
              and instances:
              <p></p>
              7.1. Third Party Services:
              <p></p>
              ThankU may in the future partner with a number of selected service
              providers, whose services and solutions will be intended to
              complement, facilitate and enhance our own. These include hosting
              and server co-location services, communications and content
              delivery networks (CDNs), data and cyber security services,
              billing and payment processing services, domain name registrars,
              fraud detection and prevention services, web analytics, e-mail
              distribution and monitoring services, session recording and remote
              access services, performance measurement, data optimization and
              marketing services, content providers, and our legal and financial
              advisors (collectively, “Third Party Service(s)”).
              <p></p>
              Such Third Party Services may receive or otherwise have access to
              our Visitors’ and Users’ Personal Information and/or
              Users-of-Users’ Personal Information, in its entirety or in part –
              depending on each of their particular roles and purposes in
              facilitating and enhancing our Services and business, and may only
              use it for such purposes.
              <p></p>
              Note that while our Services may contain links to other websites
              or services, we are not responsible for such websites’ or
              services’ privacy practices. We encourage you to be aware when you
              leave our Services and read the privacy statements of each and
              every website and service you visit before providing your Personal
              Information. This Privacy Policy does not apply to such linked
              third -party websites and services.
              <p></p>
              ThankU is accountable for Personal Information that it receives
              under the Privacy Shield and subsequently transfers to a third
              party as described in the Privacy Shield Principles. In
              particular, ThankU remains responsible and liable under the
              Privacy Shield Principles if third-party agents that it engages to
              process the Personal Information on its behalf do so in a manner
              inconsistent with the Principles, unless ThankU proves that it is
              not responsible for the event giving rise to the damage.
              <p></p>
              We may share the information of our visitors, users and their
              users-of-users with various third parties, including certain
              service providers, law enforcement officials and application
              developers.
              <p></p>
              The information may be shared solely in accordance with this
              policy.
              <p></p>
              7.2. Law Enforcement, Legal Requests and Duties:
              <p></p>
              Where permitted by local data protection laws, ThankU may disclose
              or otherwise allow others access to your Personal Information
              pursuant to a legal request, such as a subpoena, legal
              proceedings, search warrant or court order, or in compliance with
              applicable laws, if we have good faith belief that the law
              requires us to do so, with or without notice to you.
              <p></p>
              7.3. Protecting Rights and Safety:
              <p></p>
              ThankU may share your Personal Information with others if we
              believe in good faith that this will help protect the rights,
              property or personal safety of ThankU, any of our Users, or any
              member of the general public, with or without notice to you.
              <p></p>
              7.4. Social Media Features and Framed Pages:
              <p></p>
              Our Services include certain Social Media features and widgets,
              single sign on features, such as the “Facebook Connect” or “Google
              Sign-in”, the “Facebook Like” button, the “Share this” button or
              other interactive mini-programs (“Social Media Features”).These
              Social Media Features may collect information such as your IP
              address or which page you are visiting on our Website, and may set
              a cookie to enable them to function properly. Social Media
              Features are either hosted by a third party or hosted directly on
              our Services. Your interactions with these third parties’ Social
              Media Features are governed by their policies and not ours.
              <p></p>
              In addition, our Services may enable you to share your Personal
              Information with third parties directly, such as via page framing
              techniques to serve content to or from Third Party Services or
              other parties, while preserving the look and feel of our Website
              and Services (“Frames”). Please be aware that if you choose to
              interact or share any Personal Information via such Frames, you
              are in fact providing it to these third parties and not to us, and
              such interactions and sharing too are governed by such third
              parties’ policies and not ours.
              <p></p>
              8. Use of cookies and other tracking technologies
              <p></p>
              ThankU, together with its marketing, analytics and technology
              partners, uses certain monitoring and tracking technologies (such
              as cookies, beacons, pixels, tags and scripts). These technologies
              are used in order to maintain, provide and improve our Services on
              an ongoing basis, and in order to provide our Visitors, our Users
              with a better experience. For example, thanks to these
              technologies, we are able to maintain and keep track of our
              Visitor’s and Users’ preferences and authenticated sessions, to
              better secure our Services, to identify technical issues, user
              trends and effectiveness of campaigns, and to monitor and improve
              the overall performance of our Services.
              <p></p>
              Please note that Third Party Services placing cookies or utilizing
              other tracking technologies through our Services may have their
              own policies regarding how they collect and store information.
              Such practices are not covered by our Privacy Policy and we do not
              have any control over them.
              <p></p>
              Cookies: In order for some of these technologies to work properly,
              a small data file (“cookie”) must be downloaded and stored on your
              device. By default, we use several persistent cookies for purposes
              of session and user authentication, security, keeping the User’s
              preferences (e.g., regarding default language and settings),
              connection stability (e.g., for uploading media, using e-Commerce
              features, etc.), monitoring performance of our services and
              marketing campaigns, and generally providing and improving our
              Services.
              <p></p>
              If you want to delete or block any cookies, please refer to the
              help and support area on your internet browser for instructions on
              how to locate the file or directory that stores cookies.
              Information on deleting or controlling cookies is also available
              at www.aboutcookie.org (note that this website is not provided by
              ThankU, and we therefore cannot ensure its accuracy, completeness
              or availability). Please note that deleting our cookies or
              disabling future cookies or tracking technologies may prevent you
              from accessing certain areas or features of our Services, or may
              otherwise adversely affect your user experience.
              <p></p>
              Clear Gifs: We and certain Third Party Services may employ a
              software technology called “clear gifs” (a.k.a. Web Beacons/Web
              Bugs), which enables them and us to improve our Services by
              measuring their effectiveness and performance. Clear gifs are tiny
              graphics with a unique identifier, similar in function to cookies,
              however are not stored on your device, but instead embedded within
              our Services.
              <p></p>
              Flash and HTML5: We and certain Third Party Services may also
              employ certain tracking technologies known as “Flash cookies” and
              “HTML5”, mainly for advertising purposes. Various browsers may
              offer their own management tools for removing or blocking such
              technologies. Additional information about managing Flash cookies
              is available here.
              <p></p>
              (Please note that this website is not provided by ThankU, and we
              therefore cannot ensure its accuracy, completeness or
              availability).
              <p></p>
              Behavioral Targeting/Re-Targeting: Certain Third Party Services
              and ad networks may display advertising on our Website, on ThankU
              Apps, or manage our advertising on other websites. Such parties
              may use certain tracking technologies to collect certain
              information about your activities on the Services and different
              third party Services to provide you with targeted advertising
              based on your interests and preferences. You may opt-out of
              receiving targeted ads from certain advertisers and ad networks by
              clicking here (or if you are located in the European Union click
              here (provided by YourChoicesOnline.eu). Please note this does not
              opt you out of receiving all advertising and that your opt-out
              will only be in relation to the specific advertising. You will
              continue to receive advertising for which you have not opted out.
              <p></p>
              User Data Supplementation: We may receive information about you
              from other sources, including publicly available databases or
              third parties from whom we have purchased data, and combine this
              data with information we already have about you. This helps us to
              update, expand and analyze our records, identify new customers,
              and provide products and Services that may be of interest to you.
              Where we obtain your Personal Information from these third
              parties, we ensure that such parties are contractually committed
              to inform you that your Personal Information will be disclosed to
              us and we take steps to ensure the accuracy of your Personal
              Information before using it. If you provide us personal
              information about others, or if others give us your information,
              we will only use that information for the specific reason for
              which it was provided to us.
              <p></p>
              “Do Not Track” Signals: Please note that we do not change our
              practices in response to a “Do Not Track” signal in the HTTP
              header from a browser or mobile application.
              <p></p>
              We and certain third party services may use cookies and similar
              tracking technologies throughout our services.
              <p></p>
              These technologies are used mostly for stability, security,
              functionality, performance and advertising purposes.
              <p></p>
              You may block, opt-out of or otherwise manage such tracking
              technologies by yourself, through your browser settings or other
              sources – but please note that this may adversely affect the way
              you experience our services.
              <p></p>
              9. Communications from ThankU
              <p></p>
              9.1. Promotional Messages:
              <p></p>
              We may use your Personal Information to send you promotional
              content and messages by e-mail, text messages, direct text
              messages, marketing calls and similar forms of communication from
              ThankU or our partners (acting on ThankU’s behalf) through such
              means.
              <p></p>
              If you do not wish to receive such promotional messages or calls,
              you may notify ThankU at any time or follow the “unsubscribe” or
              STOP instructions contained in the promotional communications you
              receive.
              <p></p>
              We take steps to limit the promotional content we send you to a
              reasonable and proportionate level, and to send you information
              which we believe may be of interest or relevance to you, based on
              your information.
              <p></p>
              We may send you promotional messages and content.
              <p></p>
              You can easily opt-out of receiving promotional messages by
              contacting us or unsubscribing.
              <p></p>
              9.2. Service and Billing Messages:
              <p></p>
              ThankU may also contact you with important information regarding
              our Services, or your use thereof.
              <p></p>
              For example, we may send you a notice (through any of the means
              available to us) if a certain Service is temporarily suspended for
              maintenance; reply to your support ticket or e-mail; send you
              reminders or warnings regarding upcoming or late payments for your
              current or upcoming subscriptions; forward abuse complaints
              regarding your User Website; or notify you of material changes in
              our Services.
              <p></p>
              It is important that you are always able to receive such messages.
              For this reason, you are not able to opt-out of receiving such
              Service and Billing Messages unless you are no longer a ThankU
              Visitor or User (which can be done by deactivating your account).
              <p></p>
              We may also contact you with service and billing-related messages
              and content. You will not be able to opt-out of receiving such
              messages.
              <p></p>
              10. Your rights in relation to your personal information
              <p></p>
              It is imperative that you have control over your Personal
              Information. That’s why ThankU is taking steps to enable you to
              access, receive a copy of, update, amend, delete, or limit the use
              of your Personal Information.
              <p></p>
              Before disclosing the requested Personal Information, we may ask
              you for additional information in order to confirm your identity
              and for security purposes. We reserve the right to charge a fee
              where permitted by law (e.g., if your request is unfounded or
              excessive).
              <p></p>
              You have the right to file a complaint with your local supervisory
              authority for data protection (but we still recommend that you
              contact us first).
              <p></p>
              If you are a ThankU Visitor or User, and you wish to receive a
              copy, access and/or request us to make corrections to the Personal
              Information that you have stored with us, or wish to request a
              list of what Personal Information (if any) pertaining to you we
              disclosed to third parties for direct marketing purposes, please
              mail your request to thankU.app, 15 Bolton Gardens, London SW5
              0AL.
              <p></p>
              We will make all reasonable efforts to honor your request promptly
              (unless we require further information from you in order to fulfil
              your request), subject to legal and other permissible
              considerations. You may also correct, update or remove certain
              parts of such Personal Information by yourself, through your User
              account or User Website settings.
              <p></p>
              Please note that permanently deleting your ThankU account erases
              all of your information from ThankU's databases except data
              directly related with any transactions on your account. After
              completing this process, you can no longer use any of your ThankU
              services, your account and all its data other than the
              aforementioned transaction data will be removed permanently, and
              ThankU will not be able to restore your account or retrieve your
              data in the future. If you contact ThankU Support in the future,
              the system will not recognize your account and Support agents will
              not be able to locate the deleted account.
              <p></p>
              You may request to access, receive a copy of, update, amend,
              delete, or limit the use of your Personal Information you have
              stored with us. Just send us an e-mail or fill out our dedicated
              GDPR form. You may also correct and/or update your information
              through your account or website settings.
              <p></p>
              You can delete your Personal Information, other than information
              directly related you’re your historic transactions, by deleting
              your entire account.
              <p></p>
              We will respond to your requests within a reasonable timeframe.
              <p></p>
              11. Data Retention
              <p></p>
              We may retain your Personal Information for as long as your User
              account is active, as indicated in this Privacy Policy or as
              otherwise needed to provide you with our Services.
              <p></p>
              We may continue to retain such Personal Information even after you
              deactivate your User account and/or cease to use any particular
              Services, as reasonably necessary to comply with our legal
              obligations, to resolve disputes regarding our Users, prevent
              fraud and abuse, enforce our agreements and/or protect our
              legitimate interests.
              <p></p>
              We maintain a data retention policy which we apply to information
              in our care. Where your Personal Information is no longer required
              we will ensure it is securely deleted.
              <p></p>
              We may keep your information for as long as your account is
              active, and longer as needed (for example, if we are legally
              obligated to keep it longer, or need it to protect our interests).
              <p></p>
              12. Security
              <p></p>
              ThankU has implemented security measures designed to protect the
              Personal Information you share with us, including physical,
              electronic and procedural measures. Among other things, we offer
              HTTPS secure access to most areas on our Services; the
              transmission of sensitive payment information (such as a credit
              card number) through our designated purchase forms is protected by
              an industry standard SSL/TLS encrypted connection; and your
              payment-related information (e.g. credit card details) are not
              handled by us or visible to us – when you enter your credit card
              details in the ThankU app these are processed directly by PayPal,
              Apple / Stripe, and other trusted payment processing services as
              presented to you. The only payment-related information we store is
              that related to your being paid through the use of our Services,
              which at the current time is only if you have selected to be paid
              to a UK bank account, in which case we store your account name,
              account number, and sort code.
              <p></p>
              Regardless of the measures and efforts taken by ThankU, we cannot
              and do not guarantee the absolute protection and security of your
              Personal Information you upload, publish or otherwise share with
              ThankU or anyone else.
              <p></p>
              We therefore encourage you to set strong passwords for your User
              account, and avoid providing us or anyone with any sensitive
              information of which you believe its disclosure could cause you
              substantial or irreparable harm. Furthermore, because certain
              areas on our Services are less secure than others, and since
              e-mail and instant messaging are not recognized as secure forms of
              communications, we request and encourage you not to share any
              Personal Information on any of these areas or via any of these
              methods.
              <p></p>
              If you have any questions regarding the security of our Services,
              you are welcome to contact us at hello@thanku.app.
              <p></p>
              We are committed to securing your personal information, and use
              various security measures to better protect it.
              <p></p>
              However, as we can’t guarantee absolute protection – we encourage
              you to be careful, set a strong password for your account, and
              avoid submitting any sensitive information which, if exposed,
              could cause you major harm.
              <p></p>
              13. Public forums and user content
              <p></p>
              Our Services may in the future offer publicly accessible blogs,
              communities and support forums. Please be aware that any
              information you provide in any such areas may be read, collected,
              and used by others who access them. To request removal of your
              Personal Information from our blogs, communities or forums, feel
              free to contact us at: hello@thanku.app. In some cases, we may not
              be able to remove your Personal Information from such areas. For
              example, if you use a third party application to post a comment
              (e.g., the Facebook social plugin application) while logged in to
              your related profile with such third party, you must login into
              such application or contact its provider if you want to remove the
              Personal Information you posted on that platform.
              <p></p>
              In any event, we advise against posting any information (or use
              any posting means to post information) you don’t wish to publicize
              on these platforms.
              <p></p>
              Avoid posting any information to any of the public areas on our
              Services if you don’t want it to become publicly available.
              <p></p>
              14. Updates and interpretation
              <p></p>
              We may update this Privacy Policy as required by applicable law,
              and to reflect changes to our information collection, usage and
              storage practices. If we make any changes that we deem as
              “material” (in our sole good faith discretion), we will notify by
              email prior to the change becoming effective. We encourage you to
              periodically review this page for the latest information on our
              privacy practices.
              <p></p>
              Unless stated otherwise, our most current Privacy Policy applies
              to all information that we have about you with respect to our
              Website, ThankU Apps and other Services.
              <p></p>
              Any heading, caption or section title contained herein is provided
              only for convenience, and in no way defines or explains any
              section or provision hereof, or legally binds any of us in any
              way.
              <p></p>
              This Privacy Policy was written in English, and may in the future
              be translated into other languages for your convenience. If a
              translated (non-English) version of this Privacy Policy conflicts
              in any way with its English version, the provisions of the English
              version shall prevail.
              <p></p>
              We may change this policy at any time. We will notify you of any
              material changes.
              <p></p>
              Translated (non-English) versions of these terms are provided for
              convenience only.
              <p></p>
              15. Contacting us
              <p></p>
              If you have any questions about this Privacy Policy or wish to
              exercise any of your rights as described in Section 10, please
              contact us the Data Protection Officer team at hello@thanku.app.
              We will attempt to resolve any complaints regarding the use of
              your Personal Information in accordance with this Privacy Policy.
              <p></p>
              You may also contact us by mail at: Appsanely Ltd, 15 Bolton
              Gardens, London SW5 0AL, United Kingdom.
              <p></p>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                class="widebtn"
                style="width: 20%; height: 6vh"
                @click="this.doShowHome()"
              >
                Ok
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import shared from "../shared.js";
export default {
  methods: {
    doShowHome() {
      // /console.log("we're off!");

      let returnJSON = { showHomeNew: true };
      this.$emit("return-json", returnJSON);
    },
  },
  created() {
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    shared.saveToUserPath(
      this.devEnv,
      "Show terms " + new Date().toISOString(),
      this.globalPushForwardInterval
    );
  },
};
</script>
<style>
/* this makes the scroll time instantaneous */
html {
  scroll-behavior: auto !important;
}
</style>




